import { logger } from "logging/log-util";

export const pushGoogleTabEvent = (eventName, payload) => {
  if (typeof window !== undefined) {
    const { dataLayer } = window;
    if (dataLayer) {
      try {
        dataLayer.push({ ...payload, event: eventName });
        console.log("success",{ ...payload, event: eventName })
      } catch (error) {
        logger.error("error tab manager -> unable to push event", error);
      }
    } else {
      logger.error("error tab manager -> unable to find datalayer in window")
    }
  } else {
    logger.error("error tab manager -> unable to find window")
  }
};

const getPostType = (postType) => {
  if (postType === "text") {
    return "Read";
  } else if (postType === "video") {
    return "Video";
  } else if (postType === "audio") {
    return "Audio";
  } else if (postType) {
    return "Visual Stories";
  } else {
    return "Undefined";
  }
};

export const createHomeArticlePayload = (postStateData,extraInfo) => {
  let payload = {
    "Content ID": postStateData?._id,
    "Title": postStateData?.title,
    "Category": postStateData?.categoryName,
    "Sub Category": postStateData?.subCategoryName,
    // "Author name": "",
    // "Published Date": postStateData?.createdAt?.toLocaleDateString(),
    // "Published Time": postStateData?.createdAt?.toLocaleTimeString(),
    // "Published Date": (new Date(postStateData?.createdAt))?.toLocaleDateString(),
    // "Published Time": (new Date(postStateData?.createdAt))?.toLocaleTimeString(),
    "Published Date": postStateData?.createdAt,
    "Published Time": postStateData?.createdAt,
    "Hashtag Linked": postStateData?.hashtags?.join(" "),
    "Author Name": postStateData?.postedByName,
    "Share":postStateData?.shareCount,
    "Likes":postStateData?.likeCount,
    "Bookmarks":postStateData?.isBookmarked
  };
  if (extraInfo?.Section){
    return {...payload,Section:extraInfo?.Section}
  } else {
    payload.Section = getPostType(postStateData?.type)
    return payload;
  }
};

// Bookmarks

